import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button/button";

const Page = () => {
  return (
      <LessonLayout
        current_step={3}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 3</H2>

          <p>Work in a team of 2-3 students for this step. In your notebook, create a table with two columns labeled with these headings:</p>

          <div className="flex space-x-5 justify-center text-2xl" style={{ fontFamily: "Chilanka" }}>
            <strong>Accurate</strong>
            <strong>Inaccurate</strong>
          </div>

          <p>You will be looking at a website for a diet supplement to help people lose weight. As you look through the website, try to find words or phrases that might make you think the information is likely to be accurate or inaccurate. Record those words or phrases in the table in your notebook.</p>

          <Button
            action="secondary"
            className="m-2"
            color="indigo"
            href="/green-coffee-magic"
            icon="external"
            title="Green coffee bean extract website"
          >
            Green coffee bean extract website
          </Button>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
